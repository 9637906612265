import React from 'react'
import getImageUrl from '@sanity/image-url';
import client from '../util/sanity';

const Image = ({ data, alt, className }) => {
    let imgSrc
    const ImgUrl = getImageUrl(client)
    if (data) {
        imgSrc = ImgUrl.image(data).url()
    }
    return (
        <img src={imgSrc} alt={alt} className={className} />
    )
}

export default Image