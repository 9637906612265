import React, { useEffect } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Home from '../pages/Home';
import { PATH } from '../util/GlobalData';
import Products from '../pages/Products';
import About from '../pages/AboutUs';
import ProductDetails from '../pages/ProductDetails';
import Footer from '../components/Footer';
import { useDispatch } from 'react-redux';
import { ProductAction } from '../redux/actions/ProductAction';
import { ColorAction } from '../redux/actions/ColorAction';
import { OsAction } from '../redux/actions/OsAction';
import { SliderAction } from '../redux/actions/SliderAction';

const Router = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ProductAction());
        dispatch(ColorAction());
        dispatch(OsAction());
        dispatch(SliderAction());
    }, [dispatch]);

    const Layout = () => {
        return (
            <div className="bg-gray-900 w-full h-full overflow-auto text-white flex flex-col">
                <Outlet />
            </div>
        );
    };

    const TopHeader = () => {
        return (
            <React.Fragment>
                <Header />
                <Outlet />
                <Footer />
            </React.Fragment>
        );
    };

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route element={<TopHeader />}>
                    <Route path={PATH.HOME} element={<Home />} />
                    <Route path={PATH.PRODUCTS} element={<Products />} />
                    <Route path={PATH.ABOUT_US} element={<About />} />
                    <Route path={PATH.PRODUCT_DETAILS + ':id'} element={<ProductDetails />} />
                </Route>
            </Route>
        </Routes>
    );
};

export default Router;
