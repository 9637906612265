import React, { useEffect } from 'react'
import { IMG_URL } from '../../util/GlobalData'
import SectionTitle from '../../components/SectionTitle'
import Pharagraph from '../../components/Pharagraph'
import Watermark from '../../components/Watermark'
import ProductThumb from '../../components/ProductThumb'
import { useSelector } from "react-redux"
import Slide from '../../components/Slide'

const Home = () => {
    const data = useSelector((state) => state.Products)
    const slider = useSelector((state) => state.Slider)
    useEffect(() => {
        document.title = 'Dino';
    }, []);
    const LatestProduct = data?.products.slice(0, 5)
    return (
        <React.Fragment>
            <Slide data={slider?.slider} />
            <section className='relative px-4'>
                <Watermark value={'Who we are'} />
                <div className='flex py-28 items-center gap-16 container mx-auto relative z-10 md:flex-row flex-col'>
                    <div className='md:w-1/2'>
                        <SectionTitle value={'Who we are'} />
                        <Pharagraph value={`We are a specialized tablet PC manufacturer based in Shenzhen, China, offering a comprehensive range of tablet PC series designed to cater to both children and adults. Our commitment to innovation and quality has positioned us as a leading player in the B2C wholesale marketplaces across Africa and the Middle East.`} />
                        <Pharagraph value={`Our primary focus is on providing the best learning tablet PCs for children. We understand the importance of education and technology in today's world, and that's why we've dedicated ourselves to producing top-notch educational tablet solutions. Whether you're a parent looking to enhance your child's learning experience or an educator seeking tools to empower young minds, we are your go-to destination. Explore our diverse selection of kid-friendly tablet PCs, thoughtfully designed to combine fun and education seamlessly. Join us in shaping the future of learning with cutting-edge technology and vibrant, engaging tablets that inspire young learners on their educational journey.`} />
                    </div>
                    <div className='md:w-1/2'>
                        <img className='w-full' src={IMG_URL.ABOUTUS} alt="about-us" srcSet={IMG_URL.ABOUTUS} />
                    </div>
                </div>
            </section>
            {
                LatestProduct.map((item, index) => {
                    return <ProductThumb data={item} key={item?._id} reverse={index % 2 !== 0} />
                })
            }
        </React.Fragment>
    )
}

export default Home