import React, { useState } from 'react'
import { IMG_URL, PATH } from "../util/GlobalData";
import { Link } from 'react-router-dom';
import { MdSupportAgent, MdOutlineHome, MdOutlineVerifiedUser, MdProductionQuantityLimits } from "react-icons/md";
import Button from './Button';
import { Bars3CenterLeftIcon } from '@heroicons/react/24/solid'

const Header = () => {
    const [openMenu, setOpenMenu] = useState(false)
    const menuHandler = () => {
        setOpenMenu(true)
    }
    const closeHandler = () => {
        setOpenMenu(false)
    }
    return (
        <header className="flex md:justify-between items-center px-4 py-10 container mx-auto gap-4">
            <button className='md:hidden' onClick={menuHandler}>
                <Bars3CenterLeftIcon className='w-8 h-8' />
            </button>
            <img src={IMG_URL.BEBETAB} className="w-44" alt="idino" />
            <nav className='md:flex gap-4 hidden'>
                <Link className='flex items-center gap-1' to={PATH.HOME}><MdOutlineHome /> Home</Link>
                <Link className='flex items-center gap-1' to={PATH.PRODUCTS}><MdProductionQuantityLimits /> Products</Link>
                <Link className='flex items-center gap-1' to={PATH.ABOUT_US}><MdOutlineVerifiedUser /> About us</Link>
                <span className='text-gray-700'>|</span>
                <Link to={PATH.HOME} className='flex items-center gap-1 text-blue-600'> <MdSupportAgent /> Support</Link>
            </nav>
            {
                openMenu ? <div className='fixed z-20 left-0 top-0 w-full h-screen'>
                    <div className='w-full h-screen bg-black bg-opacity-30 fixed z-30'></div>
                    <div className={`bg-white w-80 h-full flex p-4 flex-col relative z-40 transition-transform ${openMenu ? 'left-0' : '-left-full'}`}>
                        <div className='flex gap-4 justify-between items-center'>
                            <img src={IMG_URL.BEBETAB} className="w-36" alt="idino" />
                            <button className='text-blue-500' onClick={closeHandler}>Close</button>
                        </div>
                        <nav className='flex flex-col text-black gap-8 mt-10'>
                            <Link className='flex items-center gap-1' to={PATH.HOME} onClick={closeHandler}><MdOutlineHome /> Home</Link>
                            <Link className='flex items-center gap-1' to={PATH.PRODUCTS} onClick={closeHandler}><MdProductionQuantityLimits /> Products</Link>
                            <Link className='flex items-center gap-1' to={PATH.ABOUT_US} onClick={closeHandler}><MdOutlineVerifiedUser /> About us</Link>
                        </nav>
                    </div>
                </div> : ''
            }
        </header>
    )
}

export default Header