import bebetab from "../assets/images/logo.svg";
import aboutus from "../assets/images/aboutUs.svg";
import dummytab from "../assets/images/tab.png";

export const IMG_URL = {
    BEBETAB: bebetab,
    ABOUTUS: aboutus,
    DUMMYTAB: dummytab,
}

export const PATH = {
    HOME: '/',
    PRODUCTS: '/products',
    ABOUT_US: '/about-us',
    PRODUCT_DETAILS: '/product-detail/'
}

export const ACTION_TYPE = {
    SLIDER: 'SLIDER',
    PRODUCTS_DETAILS: 'PRODUCTS_DETAILS',
    PRODUCTS: 'PRODUCTS',
    COLORS: 'COLORS',
    OS: 'OS',
    SUCCESS: 'SUCCESS',
    LOADING: 'LOADING',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
}