import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import { Link, useParams } from 'react-router-dom'
import SectionTitle from '../../components/SectionTitle'
import { IMG_URL } from '../../util/GlobalData'
import { MdKeyboardArrowRight, MdSystemSecurityUpdateGood, MdBatteryChargingFull, MdWifi } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import ProductDetailAction from '../../redux/actions/ProductDetailAction'
import Color from '../../components/Color'
import OsVesion from '../../components/OsVesion'
import Image from '../../components/Image'
import WhatsApp from '../../components/WhatsApp'

const ProductDetails = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const setData = useSelector((state) => state.ProductDetails)
    const data = setData?.products_details[0]
    document.title = data?.name;
    useEffect(() => {
        dispatch(ProductDetailAction(id))
    }, [dispatch, id])
    const [productColor, setProductColor] = useState()
    const handleValueChange = (e) => {
        data?.colors?.map((item) => {
            if (item?.color?._ref === e) {
                setProductColor(item?.image?.asset?._ref)
            }
        })
    }
    const whatsAppData = {
        product: data?.name,
        pageLink: window.location.href
    }
    return (
        <React.Fragment>
            <div className='border-t border-gray-700 py-8 flex md:flex-row flex-col container mx-auto items-center justify-between gap-8 px-4'>
                <h3 className='text-3xl'>{data?.name}</h3>
                <div className='flex md:flex-row flex-col items-center gap-4'>
                    <h3 className='text-3xl text-blue-600'>${data?.price}</h3>
                    <WhatsApp data={whatsAppData} />
                    {data?.softwareAndVideo?.software ? <Link className='text-gray-500'>Download Software</Link> : ''}
                </div>
            </div>
            <Image data={data?.images?.homeSlider?.asset?._ref} className='w-full mx-auto' alt={data?.name} />
            <div className='container mx-auto flex flex-col py-4 gap-4 justify-center text-center px-4'>
                <h4 className='text-2xl'>Dino Presents</h4>
                <SectionTitle value={`${data?.name} - ${data?.type === 'tablet' ? 'Tablet' : 'Mobile'}`} />
                <p className='md:w-1/2 mx-auto'>Make way for the stylish Tab. The premium metallic body, clean design and rounded edges are enough to take your breath away. Enjoy dynamic visuals on a larger screen edged with narrow bezels.</p>
            </div>
            <div className='container mx-auto flex flex-col py-4 gap-4 justify-center text-center px-4'>
                <h4 className='text-4xl'>Amazing LCD Display</h4>
                <Image data={data?.images?.displayCover?.asset?._ref} className='md:w-1/2 mx-auto' alt={data?.name} />
                <div className='flex md:flex-row flex-col justify-center gap-8 text-blue-600 items-center'>
                    <h5 className='text-2xl'>{data?.display?.size} Inches</h5>
                    <span className='md:block hidden'>|</span>
                    <h5 className='text-2xl'>{data?.display?.width} x {data?.display?.height} (Resolution)</h5>
                    <span className='md:block hidden'>|</span>
                    <h5 className='text-2xl'>{data?.display?.type}</h5>
                </div>
            </div>
            <div className={`flex md:flex-row flex-col py-24 items-center gap-20 container mx-auto px-4`}>
                <div className='md:w-1/2 flex flex-col gap-8'>
                    <h4 className='text-4xl'>System and Performance</h4>
                    <div className='flex flex-col gap-4'>
                        <div className='flex'>
                            <MdKeyboardArrowRight className='min-w-[32px] text-2xl' />
                            <p>This tablet has a {data?.display?.size} inch Immersive Display ( {data?.display?.width} x {data?.display?.height} pixels resolution ) with symetric bezel for un-interrupted visual experience for gaming, watching videos, multi-tasking and more</p>
                        </div>
                        <div className='flex'>
                            <MdKeyboardArrowRight className='min-w-[32px] text-2xl' />
                            <p>{data?.memory?.ram} GB RAM, {data?.memory?.rom} GB Internal Memory</p>
                        </div>
                        <div className='flex'>
                            <MdKeyboardArrowRight className='min-w-[32px] text-2xl' />
                            <p>{data?.camera?.back} MP Primary Camera, {data?.camera?.front} MP Front Facing Camera</p>
                        </div>
                    </div>
                </div>
                <div className='md:w-1/2'>
                    <Image data={data?.images?.frontCover?.asset?._ref} className='w-full' alt={data?.name} />
                </div>
            </div>
            <div className='container mx-auto flex flex-col py-4 gap-4 justify-center text-center px-4'>
                <h4 className='text-4xl'>Choose your best color</h4>
                <p className='md:w-1/2 mx-auto'>Make your {data?.type} uniquely yours by selecting the perfect color from our wide range of options.</p>
                <div className={`p-4 md:w-1/2 mx-auto`}>
                    <Image data={productColor ? productColor : data?.colors ? data?.colors[0]?.image?.asset?._ref : ''} className='mx-auto' alt='tab' />
                </div>
                <div className='py-4'>
                    <div className='flex gap-3 justify-center'>
                        {data?.colors?.map((items) => {
                            return <Color key={items?._key} data={items} onValueChange={handleValueChange} />
                        })}
                    </div>
                </div>
                <div className='flex md:flex-row flex-col gap-8 justify-around py-4 text-gray-600 text-2xl'>
                    <h3 className='flex items-center gap-2'><MdSystemSecurityUpdateGood /> OS: <OsVesion data={data?.os?._ref} /></h3>
                    <h3 className='flex items-center gap-2'><MdBatteryChargingFull /> Battery: {data?.battery}</h3>
                    <h3 className='flex items-center gap-2'><MdWifi /> Conectivity: {data?.connectivity?.gsm ? 'GSM' : ''} {data?.connectivity?.wifi ? 'Wi-fi' : ''}</h3>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductDetails