import { ACTION_TYPE } from '../../util/GlobalData'
const ProductState = {
    products_details: [],
    loading: Boolean
}


export const ProductDetailReducer = (state = ProductState, { type, payload }) => {
    switch (type) {
        case ACTION_TYPE.LOADING:
            return { ...state, loading: payload }
        case ACTION_TYPE.PRODUCTS_DETAILS:
            return { ...state, products_details: payload, }
        default:
            return state
    }
}
