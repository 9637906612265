import React from 'react';
import Image from './Image'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Slide({ data }) {
    let settings = {
        dots: true,
        autoplay: true,
        fade: true,
        arrows: false,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        dots: true
    };
    return (
        <Slider {...settings}>
            {data.map((item) => {
                return <Image key={item?.sliderImage?.asset?._ref} data={item?.sliderImage?.asset?._ref} alt={'dino'} />
            })
            }
        </Slider>
    )
}

export default Slide;