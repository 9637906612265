import React from 'react'
import { useSelector } from 'react-redux'

const Color = ({ data, onValueChange }) => {
    const colors = useSelector((state) => state.Color)
    const findColor = (val) => {
        return colors?.colors?.find((item) => {
            return item._id === val
        })
    }
    const colorFamily = findColor(data?.color?._ref)
    const OnClickHandler = () => {
        // console.log(colorFamily?._id);
        onValueChange(colorFamily?._id)
    }
    return (
        <div onClick={OnClickHandler} className={`cursor-pointer w-6 h-6 bg-[${colorFamily?.pickedColor?.hex}] rounded-full`} style={{ 'backgroundColor': colorFamily?.pickedColor?.hex }}></div>
    )
}

export default Color