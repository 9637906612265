import { ACTION_TYPE } from '../../util/GlobalData'
import client from '../../util/sanity';

export const ColorAction = () => async (dispatch) => {
    await dispatch({
        type: ACTION_TYPE.LOADING,
        payload: true
    })
    try {
        const data = await client.fetch('*[_type == "colors"]');
        await dispatch({
            type: ACTION_TYPE.COLORS,
            payload: data,
        })
        dispatch({
            type: ACTION_TYPE.LOADING,
            payload: false
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: ACTION_TYPE.FAILED,
            error: 'Failed to fetch Sanity data',
        });
    }
}
