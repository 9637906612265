import React from 'react'

const Watermark = ({ value, className, position }) => {
    return (
        <h1 className={`text-7xl 
                        absolute 
                        text-gray-800 ${position ? position : 'left'}-0 
                        top-1/2 
                        z-0 
                        ${className ? className : ''}`}>
            {value}
        </h1>
    )
}

export default Watermark