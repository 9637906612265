import { combineReducers } from 'redux'
import { ProductReducer } from './ProductReducer'
import { ColorReducer } from './ColorReducer'
import { OsReducer } from './OsReducer'
import { ProductDetailReducer } from './ProductDetailReducer'
import { SliderReducer } from './SliderReducer'

const reducers = combineReducers({
    Products: ProductReducer,
    Color: ColorReducer,
    Os: OsReducer,
    ProductDetails: ProductDetailReducer,
    Slider: SliderReducer
})

export default reducers