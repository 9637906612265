import React from 'react'
import Textbox from './Textbox'
import { Link } from 'react-router-dom'
import { GrTwitter, GrLinkedin, GrFacebook } from "react-icons/gr";

const Footer = () => {
    return (
        <footer className='container mx-auto text-gray-600'>
            {/* <div className='flex flex-col md:flex-row border-y border-gray-800 py-8 items-center gap-8'>
                <div className='flex flex-col gap-4 md:w-1/2'>
                    <h4>Subscribe for new update</h4>
                    <Textbox placeholder={'Enter email address'} />
                </div>
            </div> */}
            <div className='flex flex-col md:flex-row border-y border-gray-800 py-8 items-center gap-8'>
                <p className='text-center'>Copyright 1999-2020. All Rights Reserved.</p>
                <div className='md:w-1/2'>
                    <div className='flex items-center gap-2 justify-end'>
                        <p>Our Social Media:</p>
                        <div className='flex gap-2'>
                            <Link to={'/'}><GrFacebook /></Link>
                            <Link to={'/'}><GrTwitter /></Link>
                            <Link to={'/'}><GrLinkedin /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer