import React from 'react'

const Button = ({ type, value, onclick }) => {
    const setType = () => {
        switch (type) {
            case 'primary':
                return 'bg-blue-700 text-white';
            case 'primary-outline':
                return 'border-blue-700 text-blue-700 border'
            default:
                return 'bg-blue-700 text-white';
        }
    }
    return (
        <button className={`px-8 py-2 rounded-full ${setType()}`} onClick={onclick}>{value}</button>
    )
}

export default Button