import React from 'react'
import Watermark from './Watermark'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { PATH } from '../util/GlobalData'
import SectionTitle from './SectionTitle'
import Button from './Button'
import { useNavigate } from "react-router-dom";
import Color from './Color'
import OsVesion from './OsVesion'
import Image from './Image'
import WhatsApp from './WhatsApp'


const ProductThumb = ({ reverse, data }) => {
    const navigate = useNavigate()
    const navigateTo = () => {
        navigate(PATH.PRODUCT_DETAILS + data?._id)
    }
    const handleValueChange = (e) => {
        console.log(e)
    }
    const whatsAppData = {
        product: data?.name,
        pageLink: `https://www.bebetabs.com/${PATH.PRODUCT_DETAILS + data?._id}`
    }
    return (
        <section className='relative px-4'>
            <Watermark value={`${data?.type === 'tablet' ? 'Tab' : 'Mobile'} - ${data?.name}`} position={reverse ? 'right' : 'left'} />
            <div className={`flex py-24 items-center gap-20 container mx-auto relative z-10 md:flex-row flex-col ${reverse ? 'md:flex-row-reverse' : ''}`}>
                <div className='md:w-1/2 flex flex-col gap-4 order-2 md:order-none'>
                    <SectionTitle value={data?.name} />
                    <div className='flex items-center gap-4'>
                        <h4 className='text-xl text-blue-700'>${data?.price}</h4>
                        {data?.softwareAndVideo?.software ? <React.Fragment>| <Link className='text-gray-500'>Download Software</Link></React.Fragment> : ''}
                        {data?.softwareAndVideo?.video ? <React.Fragment>| <Link className='text-gray-500'>Video</Link></React.Fragment> : ''}
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='flex'>
                            <MdKeyboardArrowRight className='min-w-[32px] text-2xl' />
                            <p>This tablet has a {data?.display?.size} inch Immersive Display ( {data?.display?.width} X {data?.display?.height} pixels resolution ) with symetric bezel for un-interrupted visual experience for gaming, watching videos, multi-tasking and more</p>
                        </div>
                        <div className='flex'>
                            <MdKeyboardArrowRight className='min-w-[32px] text-2xl' />
                            <p>{data?.memory?.ram} GB RAM, {data?.memory?.rom} GB Internal Memory</p>
                        </div>
                        <div className='flex'>
                            <MdKeyboardArrowRight className='min-w-[32px] text-2xl' />
                            <p>{data?.camera?.back} MP Primary Camera, {data?.camera?.front} MP Front Facing Camera</p>
                        </div>
                    </div>
                    <div className='flex flex-col divide-y divide-gray-700'>
                        <div className='flex'>
                            <div className='py-4 w-1/2'>
                                Colors
                            </div>
                            <div className='py-4 w-1/2'>
                                <div className='flex gap-3'>
                                    {data?.colors?.map((items) => {
                                        return <Color key={items?._key} data={items} onValueChange={handleValueChange} />
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='py-4 w-1/2'>
                                OS
                            </div>
                            <div className='py-4 w-1/2'>
                                <OsVesion data={data?.os?._ref} />
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='py-4 w-1/2'>
                                Connectivity Type
                            </div>
                            <div className='py-4 w-1/2 flex gap-4'>
                                {data?.connectivity?.gsm ? 'GSM' : ''} {data?.connectivity?.wifi ? 'Wi-fi' : ''}
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <Button type={'primary'} value={'Know More'} onclick={navigateTo} />
                        <WhatsApp data={whatsAppData} />
                    </div>
                </div>
                <div className='md:w-1/2 order-1 md:order-none'>
                    <Image data={data?.images?.frontCover?.asset?._ref} className='w-full' alt={data?.name} />
                </div>
            </div>
        </section>
    )
}

export default ProductThumb