import React, { useEffect } from 'react'

const About = () => {
    useEffect(() => {
        document.title = 'About Us';
    }, [])
    return (
        <div className='container mx-auto'>
            <h1>Coming soon...</h1>
        </div>
    )
}

export default About