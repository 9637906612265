import React from 'react'
import { useSelector } from 'react-redux'

const OsVesion = ({ data }) => {
    const os = useSelector((state) => state.Os)
    const findOS = (val) => {
        return os?.os?.find((item) => {
            return item._id === val
        })
    }
    const version = findOS(data)
    return (
        <span>{version?.name + ' ' + version?.version} ({version?.codename})</span>
    )
}

export default OsVesion