import { ACTION_TYPE } from '../../util/GlobalData'
const SliderState = {
    slider: [],
    loading: Boolean
}


export const SliderReducer = (state = SliderState, { type, payload }) => {
    switch (type) {
        case ACTION_TYPE.LOADING:
            return { ...state, loading: payload }
        case ACTION_TYPE.SLIDER:
            return { ...state, slider: payload, }
        default:
            return state
    }
}
