import { ACTION_TYPE } from '../../util/GlobalData'
const ColorState = {
    colors: [],
    loading: Boolean
}


export const ColorReducer = (state = ColorState, { type, payload }) => {
    switch (type) {
        case ACTION_TYPE.LOADING:
            return { ...state, loading: payload }
        case ACTION_TYPE.COLORS:
            return { ...state, colors: payload, }
        default:
            return state
    }
}
