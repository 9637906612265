import React, { useEffect } from 'react'
import ProductThumb from '../../components/ProductThumb'
import Textbox from '../../components/Textbox'
import { useSelector } from 'react-redux'

const Products = () => {
    const data = useSelector((state) => state.Products)
    useEffect(() => {
        document.title = 'Products';
    }, [])
    return (
        data?.loading ? 'Loading'
            : <React.Fragment>
                <div className='border-y border-gray-700 py-8 flex md:flex-row flex-col container mx-auto items-center justify-between gap-8 px-4'>
                    <h3 className='text-3xl'>Products</h3>
                    <Textbox placeholder={'Search...'} />
                </div>
                {
                    data?.products.map((item) => {
                        return <ProductThumb data={item} key={item?._id} />
                    })
                }
            </React.Fragment>
    )
}

export default Products