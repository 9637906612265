import { ACTION_TYPE } from '../../util/GlobalData'
const OsState = {
    os: [],
    loading: Boolean
}


export const OsReducer = (state = OsState, { type, payload }) => {
    switch (type) {
        case ACTION_TYPE.LOADING:
            return { ...state, loading: payload }
        case ACTION_TYPE.OS:
            return { ...state, os: payload, }
        default:
            return state
    }
}
